'use client';

import Link from 'next/link';
import { UserAvatar } from './UserAvatar';
import { User } from '@generaltranslation/node/types/Platform';
import ThemeToggle from './ThemeToggle';
import {
  Book,
  BookCheck,
  ChevronDown,
  ChevronUp,
  Languages,
  MessageSquareText,
  SunMoon,
} from 'lucide-react';
import { useState } from 'react';
import Logo from './Logo';
import { Button } from '@/components/ui/button';
import Image from 'next/image';
import { T } from 'gt-next';
import LocaleDropdown from '@/components/frame/LocaleDropdown';
import { LocaleSelector } from 'gt-next/client';

export function SignedOutNavigation() {
  return (
    <nav className="flex items-center space-x-4 lg:space-x-6 font-wide text-md">
      <T>
        <a
          className="md:text-md hover:underline underline-offset-4"
          href="https://generaltranslation.com/docs"
        >
          Docs
        </a>
        <Link
          className="md:text-md hover:underline underline-offset-4"
          href="/pricing"
        >
          Pricing
        </Link>
        <a
          className="md:text-md hover:underline underline-offset-4"
          href="https://generaltranslation.com/blog"
        >
          Blog
        </a>
        <DashboardLink user={null} overrideHREF="/dashboard">
          Dashboard
        </DashboardLink>
      </T>
    </nav>
  );
}

function DashboardLink({
  children,
  user,
  overrideHREF = '',
}: {
  children: any;
  user: User | null;
  overrideHREF?: '' | '/dashboard' | '/signin';
}) {
  const dashboardLinkClass =
    'font-medium hover:underline underline-offset-4 tracking-wide text-md';

  if (overrideHREF) {
    return (
      <Link href={overrideHREF} className={dashboardLinkClass}>
        {children}
      </Link>
    );
  }

  let href = '/dashboard';

  if (!user) {
    href = '/signin';
  }

  if (
    user &&
    user.current_org.projects &&
    user.current_org.projects.length === 1 &&
    user.current_org.projects[0]
  ) {
    href = `/dashboard/${user.current_org.projects[0].id}`;
  }

  return (
    <T>
      <span className="flex items-center gap-4 lg:gap-6 font-medium">
        <Link href={href} className={dashboardLinkClass}>
          Dashboard
        </Link>
        <a
          className="max-md:hidden md:text-md hover:underline underline-offset-4"
          href="docs"
        >
          Docs
        </a>
        <Link
          className="max-md:hidden md:text-md hover:underline underline-offset-4"
          href="/pricing"
        >
          Pricing
        </Link>
        <a
          className="max-md:hidden md:text-md hover:underline underline-offset-4"
          href="blog"
        >
          Blog
        </a>
      </span>
    </T>
  );
}

export default function Navigation({ session }: any) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="flex justify-between items-center max-w-full">
        <nav className="flex items-center gap-2 lg:gap-6">
          <Logo />
          <DashboardLink user={session?.user}>
            <T>Dashboard</T>
          </DashboardLink>
        </nav>
        <nav className="flex items-center gap-2 lg:gap-6">
          {session ? (
            <span className="flex gap-4 md:gap-6">
              <span className="flex items-center gap-2 md:gap-4">
                <ThemeToggle className="max-md:hidden" />
                <LocaleDropdown className="w-[180px] max-md:hidden h-9" />
                <Button
                  variant="outline"
                  size="sm"
                  asChild
                  className="inline-flex items-center rounded-full border"
                >
                  <a
                    href="https://github.com/General-Translation/gt-next"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-1"
                  >
                    <span className="max-md:hidden">⭐</span>
                    <Image
                      src="/github-mark.svg"
                      alt="GitHub"
                      width={16}
                      height={16}
                      className="dark:invert"
                    />
                  </a>
                </Button>
              </span>
              <UserAvatar user={session.user} />
            </span>
          ) : (
            <span className="flex items-center gap-4">
              <ThemeToggle className="max-md:hidden" />
              <LocaleDropdown className="w-[180px] max-md:hidden h-9" />
              <Button
                variant="outline"
                size="sm"
                asChild
                className="inline-flex items-center rounded-full border"
              >
                <a
                  href="https://github.com/General-Translation/gt-next"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 p-1"
                >
                  <span className="max-md:hidden">⭐</span>
                  <Image
                    src="/github-mark.svg"
                    alt="GitHub"
                    width={16}
                    height={16}
                    className="dark:invert"
                  />
                </a>
              </Button>
              <span className="md:hidden">
                {open ? (
                  <ChevronUp
                    className="cursor-pointer"
                    onClick={() => {
                      setOpen(false);
                    }}
                  />
                ) : (
                  <ChevronDown
                    className="cursor-pointer"
                    onClick={() => {
                      setOpen(true);
                    }}
                  />
                )}
              </span>
            </span>
          )}
        </nav>
      </div>
      {open && (
        <div className="md:hidden mt-6 mb-4">
          <div className="flex flex-col gap-4 px-2">
            <T>
              <Link href="https://generaltranslation.com/pricing">
                <div className="flex gap-4">
                  <BookCheck />
                  Pricing
                </div>
              </Link>
              <a href="https://generaltranslation.com/docs">
                <div className="flex gap-4">
                  <Book />
                  Documentation
                </div>
              </a>
              <a href="https://generaltranslation.com/blog">
                <div className="flex gap-4">
                  <MessageSquareText />
                  Blog
                </div>
              </a>
            </T>
            <div className="flex gap-4 items-center">
              <LocaleDropdown />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
