'use client';

import { signIn, signOut } from 'next-auth/react';
import { useRouter } from 'next/navigation';

type SignInOutProps = {
  children: React.ReactNode;
  className?: string;
  options?: any;
};

export function SignIn({ children }: SignInOutProps) {
  return (
    <div
      onClick={() => {
        signIn();
      }}
    >
      {children}
    </div>
  );
}

export function SignOut({ children, className }: SignInOutProps) {
  return (
    <div
      onClick={() => {
        signOut({ redirectTo: '/' });
      }}
      className={className}
    >
      {children}
    </div>
  );
}

export function SignOutWithRedirect({
  children,
  className,
  redirectTo,
}: {
  children: React.ReactNode;
  className?: string;
  redirectTo: string;
}) {
  return (
    <div
      onClick={() => {
        signOut({ redirectTo: redirectTo });
      }}
      className={className}
    >
      {children}
    </div>
  );
}
