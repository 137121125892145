'use client';

import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

import { User } from '@generaltranslation/node/types/Platform.js';

import { Button } from '../ui/button';
import { SignOut } from '@/components/accounts/SignInOut';
import { BookCheck, BookIcon, LogOut, MessageSquareText } from 'lucide-react';
import Link from 'next/link';
import { T } from 'gt-next';

export function UserAvatar({ user }: { user: User }) {
  return (
    <nav className="flex items-center space-x-4 lg:space-x-6">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="relative h-8 w-8 rounded-full">
            <Avatar>
              <AvatarImage src={user.image || ''} alt={user.name || ''} />
              <AvatarFallback>
                {user.name?.split(' ')?.map((word: string) => word[0]) || ''}
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="z-[150]" align="end" forceMount>
          <DropdownMenuLabel className="text-md word-break">
            <p>{user.name || ''}</p>
            <p className="text-sm font-medium text-muted-foreground overflow-hidden text-ellipsis">
              {user.email || ''}
            </p>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <T>
            <a href="/docs">
              <DropdownMenuItem className="text-md cursor-pointer">
                <BookIcon />
                Documentation
              </DropdownMenuItem>
            </a>
            <Link href="/pricing">
              <DropdownMenuItem className="text-md cursor-pointer">
                <BookCheck />
                Pricing
              </DropdownMenuItem>
            </Link>
            <a href="/blog">
              <DropdownMenuItem className="text-md cursor-pointer">
                <MessageSquareText />
                Blog
              </DropdownMenuItem>
            </a>
            <DropdownMenuSeparator />
            <SignOut>
              <DropdownMenuItem className="text-md cursor-pointer">
                <LogOut /> Sign Out
              </DropdownMenuItem>
            </SignOut>
          </T>
        </DropdownMenuContent>
      </DropdownMenu>
    </nav>
  );
}
